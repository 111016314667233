<template>
  <div
       :class="{ 'shrinkreport-content': drawer && !isMobile }"
       style="margin-top: 65px; padding: 5px">

    <Breadcrumbs style="display:inline-block;" />
    <v-container fluid class="ml-4">
      <div v-if="visibleNews.length == 0">{{ $t("news.news_not_available") }}</div>
      <div v-else>
        <ul id="news">
          <li v-for="news of visibleNews" v-bind:key="news.id" class="mb-3">
            <router-link class="newsAll-container" :to="'/news/' + news.id">
              {{ news.title }}<br/>
              {{ moment(news.createdAt).format('YYYY-MM-DD HH:mm') }}
          </router-link>
          </li>
        </ul>
      </div>
      <v-pagination  v-if="pagesCount > 1"
                    v-model="page"
                    :length="pagesCount"
                    circle></v-pagination>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Breadcrumbs from "@/components/Breadcrumbs";
import authHeader from "@/services/auth-header";
import constants from "@/constants";
const moment = require('moment');

const axios = require('axios');
var API_URL = constants.API_URL + "news/";

export default {
  name: "News",
  components: {
    Breadcrumbs
  },
  watch: {
    page() {
      this.$refs.newsContainer.scrollTo({ top: 0, behavior: "smooth" });
    }
  },
  data: () => {
    return {
      news: null,
      loadingNews: true,
      page: 1,
      perPage: 24
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
    }),
    visibleNews() {
      if (!this.news) return [];
      return this.news.slice((this.page - 1) * this.perPage, this.page * this.perPage)
    },
    pagesCount() {
      if (!this.news) {
        return 0;
      }
      return Math.ceil(this.news.length / this.perPage);
    }
  },
  methods: {
    ...mapActions({
    }),
    moment,
    async reloadNews() {
      try {
        this.loadingNews = true;

        this.page = 1;
        let res = await axios.get(API_URL + "?language=" + this.$i18n.locale, { headers: authHeader() });

        if (res && res.data) {
          this.news = res.data.news;

          for (let news of this.news) {
            news.thumbnailUrl = '123';
          }
        }
      }
      catch (err) {
        alert(err);
      }
      finally {
        this.loadingNews = false;
      }
    },
  },
  async mounted() {
    this.reloadNews();

    this.$store.dispatch("setBreadcrumbs", [
      { text: this.$t("news.news") }
    ]);
  },
};
</script>

<style scoped>
.newsAll-container {
  text-decoration: none;
}

.newsAll-container:hover {
  text-decoration: underline;
}
</style>